<template>
  <LoadingScreen v-if="!$store.state.newSignUpForm" />
  <div class="newSignUp-container">
    <section class="signUpPage-wrapper">
      <div class="signUpPage-wrapper__left-column">
        <!-- Conditionally render form or success message -->

        <form
          v-if="!isSubmitted"
          @submit.prevent="submitFormLocal"
          class="new-signUpPage-form"
          novalidate
        >
          <div class="newsignup-title-container">
            <h2 class="f-poppins-semiBold newsignup-header">
              {{ $store.state.newSignUpForm?.getDemoTitle }}
            </h2>
            <p class="f-poppins-regular newsignup-description">
              {{ $store.state.newSignUpForm?.getDemoDescription }}
            </p>
          </div>
          <!-- Your Name -->
          <div class="form-group">
            <label for="name" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.fullNameLabel
            }}</label>
            <input
              type="text"
              id="name"
              :placeholder="$store.state.newSignUpForm?.fullNamePlaceholder"
              v-model="FormData.full_name"
              @blur="validateName"
              :class="{ invalid: FromErrorFlags.full_name }"
              required
            />
          </div>
          <div class="form-group">
            <label for="name" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.jobLabel
            }}</label>
            <input
              type="text"
              id="name"
              :placeholder="$store.state.newSignUpForm?.jobPlaceholder"
              v-model="FormData.job_title"
              @blur="validateJobTitle"
              :class="{ invalid: FromErrorFlags.job_title }"
              required
            />
          </div>

          <!-- Phone -->
          <div class="form-group">
            <label for="phone" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.phoneLabel
            }}</label>
            <div class="d-flex special-phone-input">
              <div class="select-container">
                <select
                  v-model="FormData.country_code"
                  class="country-code-dropdown"
                  @focus="isOpen = true"
                  @blur="isOpen = false"
                >
                  <option
                    v-for="nationality in $store.state.nationalities"
                    :value="nationality.code"
                    :key="'mob-country-code-' + nationality.id"
                    :data-text="
                      nationality.country_name + ' (' + nationality.code + ')'
                    "
                  >
                    {{ nationality.country_name }} ({{ nationality.code }})
                  </option>
                </select>
                <span class="select-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.546"
                    height="7.761"
                    viewBox="0 0 13.546 7.761"
                  >
                    <path
                      d="M10.718,5.346,5.434,0,0,5.346"
                      transform="translate(12.132 6.761) rotate(180)"
                      fill="none"
                      stroke="#7f7f7f"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </div>
              <input
                type="tel"
                id="phone"
                :placeholder="$store.state.newSignUpForm?.phonePlaceholder"
                v-model="FormData.number"
                @blur="validatePhone"
                :class="{ invalidSpecial: FromErrorFlags.number }"
                required
              />
            </div>
          </div>

          <div class="form-group">
            <label for="name" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.companyLabel
            }}</label>
            <input
              type="text"
              id="name"
              :placeholder="$store.state.newSignUpForm?.companyPlaceholder"
              v-model="FormData.merchant_name"
              @blur="validateMerchantName"
              :class="{ invalid: FromErrorFlags.merchant_name }"
              required
            />
          </div>

          <!-- Business Website or Social -->
          <div class="form-group">
            <label for="website" class="f-poppins-medium">{{
              $store.state.newSignUpForm?.websiteLabel
            }}</label>
            <input
              type="url"
              id="website"
              :placeholder="$store.state.newSignUpForm?.websitePlaceholder"
              v-model="FormData.instagram_url"
              @blur="validateWebsite"
              :class="{ invalid: FromErrorFlags.instagram_url }"
            />
          </div>

          <!-- Submit Button -->
          <button
            @click.once="submitFormLocal"
            type="button"
            class="w-100 f-poppins-medium new-signup-button"
            :disabled="hasValidationErrors"
          >
            {{ $store.state.newSignUpForm?.submitButton }}
          </button>
        </form>

        <!-- Success Message -->
        <div v-else>
          <div class="newsignup-congratulation-container">
            <div class="newsignup-congratulation">
              <Congratulation>
                <h3 class="f-poppins-semiBold newsignup-header">
                  {{ $store.state.newSignUpForm?.congratulationTitle }}
                </h3>
                <p class="f-poppins-regular newsignup-description">
                  {{ $store.state.newSignUpForm?.congratulationDescription }}
                </p>
              </Congratulation>
              <button
                class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill text-center"
                @click="handleCongratulations"
              >
                {{ $store.state.newSignUpForm?.congratulationDone }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="signUpPage-wrapper__right-column">
        <div>
          <h3 class="newsignup-header">
            {{ $store.state.newSignUpForm?.getDemoSecondaryTitle }}
            <span class="f-poppins-semiBold"
              >{{ $store.state.newSignUpForm?.getDemoSecondaryTitleBold + " " }}
            </span>
            <span class="hide-for-pc">
              {{
                $store.state.newSignUpForm?.getDemoSecondaryTitlexlOnly
              }}</span
            >
          </h3>
          <p class="f-poppins-regular newsignup-description">
            {{ $store.state.newSignUpForm?.getDemoSecondaryDescription }}
          </p>
        </div>
        <div class="brands-grid-wrapper">
          <div class="brands-grid-Subwrapper">
            <!-- Loop through the images array -->
            <div
              v-for="(brandItem, index) in isMobile
                ? $store.state.newSignUpForm?.imagesMobile.data
                : $store.state.newSignUpForm?.images.data"
              :key="index"
              class="grid-item"
            >
              <a
                :href="brandItem.attributes.caption"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  :src="$store.state.baseUrl + brandItem.attributes.url"
                  alt="Brand Image"
                  class="grid-image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { axiosAPI } from "@/axiosClient";
import { toast } from "vue3-toastify";
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import Congratulation from "@/components/Form/common/Congratulation.vue";

import router from "@/router";

export default {
  name: "NewBrandsFormVer2",
  components: {
    Congratulation,
    LoadingScreen,
  },
  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    // imageURl() {},
    hasValidationErrors() {
      return (
        this.FromErrorFlags.full_name ||
        this.FromErrorFlags.job_title ||
        this.FromErrorFlags.merchant_name ||
        this.FromErrorFlags.number ||
        this.FromErrorFlags.instagram_url
      );
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 569 && window.innerWidth > 370,
      FormData: {
        full_name: "",
        job_title: "",
        merchant_name: "",
        //email: "",
        number: "",
        instagram_url: "",
        country_code: "+971",
      },
      FromErrorFlags: {
        full_name: false,
        job_title: false,
        merchant_name: false,
        //email: false,
        number: false,
        instagram_url: false,
      },
      FromErrorMessages: {
        full_name: [],
        job_title: [],
        merchant_name: [],
        // email: [],
        number: [],
        instagram_url: [],
      },
      isSubmitted: false, // New flag to track form submission status
    };
  },

  watch: {
    // Watch for changes in newSignUpForm and log it
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getNewSignUpForm");
      }
    },
    "$store.state.nationalities": {
      handler() {
        this.$nextTick(() => {
          this.updateDropdownDisplay();
        });
      },
      deep: true,
    },

    // Watch the form data country code
    "FormData.country_code"() {
      this.$nextTick(() => {
        this.updateDropdownDisplay();
      });
    },
    "FormData.full_name"() {
      this.FromErrorFlags.full_name = false;
      this.FromErrorMessages.full_name = [];
    },
    "FormData.job_title"() {
      this.FromErrorFlags.job_title = false;
      this.FromErrorMessages.job_title = [];
    },
    "FormData.company"() {
      this.FromErrorFlags.job_title = false;
      this.FromErrorMessages.job_title = [];
    },
    "FormData.email"() {
      this.FromErrorFlags.email = false;
      this.FromErrorMessages.email = [];
    },
    "FormData.number"() {
      this.FromErrorFlags.number = false;
      this.FromErrorMessages.number = [];
    },
    "FormData.instagram_url"() {
      this.FromErrorFlags.instagram_url = false;
      this.FromErrorMessages.instagram_url = [];
    },
  },

  created() {
    // Dispatch an action to fetch the newSignUpForm data from the store if needed
    this.$store.dispatch("getNewSignUpForm");
    this.$store.dispatch("getNationalities");
  },
  /* before */
  mounted() {
    //let app = this;
    window.addEventListener("resize", this.handleResize);
    this.setupDropdownListeners();

    // Initial update after component is mounted
    this.$nextTick(() => {
      this.updateDropdownDisplay();
    });
  },
  updated() {
    // Update display after any component updates
    this.updateDropdownDisplay();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.removeDropdownListeners();
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 569 && window.innerWidth > 370;
    },
    handleCongratulations() {
      router.push({ path: "/" });
    },
    updateDropdownDisplay() {
      const dropdowns = document.querySelectorAll(".country-code-dropdown");
      dropdowns.forEach((dropdown) => {
        // Update selected option to show only code
        if (dropdown.selectedIndex > 0) {
          const selectedOption = dropdown.options[dropdown.selectedIndex];
          selectedOption.text = selectedOption.value;
        }
      });
    },

    handleDropdownOpen(event) {
      const dropdown = event.target;
      // Restore full text for all options
      Array.from(dropdown.options).forEach((option) => {
        if (option.dataset.text) {
          option.text = option.dataset.text;
        }
      });

      // Keep selected option as code only
      if (dropdown.selectedIndex > 0) {
        dropdown.options[dropdown.selectedIndex].text = dropdown.value;
      }
    },

    handleDropdownChange(event) {
      const dropdown = event.target;
      const selectedOption = dropdown.options[dropdown.selectedIndex];
      selectedOption.text = dropdown.value;
    },

    setupDropdownListeners() {
      const dropdowns = document.querySelectorAll(".country-code-dropdown");
      dropdowns.forEach((dropdown) => {
        dropdown.addEventListener("mousedown", this.handleDropdownOpen);
        dropdown.addEventListener("change", this.handleDropdownChange);
      });
    },

    removeDropdownListeners() {
      const dropdowns = document.querySelectorAll(".country-code-dropdown");
      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener("mousedown", this.handleDropdownOpen);
        dropdown.removeEventListener("change", this.handleDropdownChange);
      });
    },
    submitFormLocal() {
      // Reset error messages
      this.FromErrorMessages = {
        full_name: [],
        job_title: [],
        merchant_name: [],
        email: [],
        number: [],
        instagram_url: [],
      };
      let errorFlag = false;

      // Validate full_name
      if (!this.FormData.full_name || this.FormData.full_name.trim() === "") {
        this.FromErrorFlags.full_name = true;
        this.FromErrorMessages.full_name.push("Your name cannot be empty.");
        errorFlag = true;
      }
      if (!this.FormData.job_title || this.FormData.job_title.trim() === "") {
        this.FromErrorFlags.job_title = true;
        this.FromErrorMessages.job_title.push("Job title cannot be empty.");
        errorFlag = true;
      }
      if (
        !this.FormData.merchant_name ||
        this.FormData.merchant_name.trim() === ""
      ) {
        this.FromErrorFlags.merchant_name = true;
        this.FromErrorMessages.merchant_name.push(
          "Company name cannot be empty."
        );
        errorFlag = true;
      }
      // Validate phone number
      const phoneNumber = this.FormData.number.trim();
      if (!phoneNumber || phoneNumber.length < 5 || isNaN(phoneNumber)) {
        this.FromErrorFlags.number = true;
        this.FromErrorMessages.number.push(
          "Phone number must be at least 5 digits and numeric."
        );
        errorFlag = true;
      }

      // Validate instagram_url
      this.validateWebsite();

      // If there are validation errors, stop the form submission
      if (errorFlag) {
        return;
      }

      // Submit form data
      if (localStorage.getItem("utm_source")) {
        this.FormData["source"] = localStorage.getItem("utm_source");
      } else {
        this.FormData["source"] = "";
      }
      if (localStorage.getItem("utm_medium")) {
        this.FormData["utm_medium"] =
          localStorage.getItem("utm_medium") + "-demo-form";
      } else {
        this.FormData["utm_medium"] = "demo-form";
      }
      if (localStorage.getItem("utm_campaign")) {
        this.FormData["utm_campaign"] = localStorage.getItem("utm_campaign");
      } else {
        this.FormData["utm_campaign"] = "";
      }
      if (localStorage.getItem("adset")) {
        this.FormData["adset"] = localStorage.getItem("adset");
      } else {
        this.FormData["adset"] = "";
      }
      if (localStorage.getItem("ad")) {
        this.FormData["ad"] = localStorage.getItem("ad");
      } else {
        this.FormData["ad"] = "";
      }
      //this.FormData["email"] = "admin@alist.ae";
      axiosAPI
        .post(`v1/brands/web/min-signup`, {
          ...this.FormData,
          number:
            `${this.FormData.country_code}${this.FormData.number}`.trim(),
        })
        .then((response) => {
          if (response.data && response.data.status) {
            //toast.success("Sign-up successful!");
            this.resetForm();
            this.isSubmitted = true;
          } else {
            toast.error("Sign-up failed! Please try again.");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            let error_msgs = [];
            for (const error_msg in error.response.data) {
              if (error.response.data[error_msg].length > 0) {
                error_msgs.push(...error.response.data[error_msg]);
                this.FromErrorFlags[error_msg] = true;
              }
            }
            toast.info(error_msgs[0]);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    },
    resetForm() {
      this.FormData = {
        full_name: "",
        //email: "",
        job_title: "",
        merchant_name: "",
        number: "",
        instagram_url: "",
        country_code: "+971",
      };
      this.FromErrorFlags = {
        full_name: false,
        //email: false,
        job_title: false,
        merchant_name: false,
        number: false,
        instagram_url: false,
      };
      this.FromErrorMessages = {
        full_name: [],
        // email: [],
        job_title: [],
        merchant_name: [],
        number: [],
        instagram_url: [],
      };
    },

    validateName() {
      this.FromErrorFlags.full_name = this.FormData.full_name.trim() === "";
    },
    validateJobTitle() {
      this.FromErrorFlags.job_title = this.FormData.job_title.trim() === "";
    },
    validateMerchantName() {
      this.FromErrorFlags.merchant_name =
        this.FormData.merchant_name.trim() === "";
    },

    validatePhone() {
      const phoneNumber = this.FormData.number.trim();
      this.FromErrorFlags.number = !(
        phoneNumber &&
        phoneNumber.length >= 5 &&
        !isNaN(phoneNumber)
      );
    },

    validateWebsite() {
      // Regular expression to match any valid URL format
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?(www\\.)?[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,}){1,}(\\/[-a-zA-Z0-9@:%._\\+~#=]*)?$"
      );

      if (
        !this.FormData.instagram_url ||
        !urlPattern.test(this.FormData.instagram_url)
      ) {
        // Set error flag and message if URL is invalid
        this.FromErrorFlags.instagram_url = true;
        this.FromErrorMessages.instagram_url = ["Please enter a valid URL."];
      } else {
        // Clear error if URL is valid
        this.FromErrorFlags.instagram_url = false;
        this.FromErrorMessages.instagram_url = [];
      }
    },
  },
};
</script>
<style>
/* brands grid layout */

.brands-grid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 2rem;
  margin-inline: auto;
}

.brands-grid-Subwrapper {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap onto new rows */
  justify-content: center; /* Adds even spacing between items */
  gap: 8px; /* Adds gap between items */
}

.grid-item {
  width: 104px;
  height: 104px;
  border-radius: 13px;
  border: 1px solid #dbdbdb;
  flex: 0 0 104px; /* Ensures each item takes exactly 104px width */
}

@media (max-width: 48rem) {
  .brands-grid-wrapper {
    width: 100%;
    margin-inline: auto;
  }
  .brands-grid-Subwrapper {
    gap: 13px; /* Adds gap between items */
  }
  .grid-item {
    width: 116px;
    height: 116px;
    flex: 0 0 116px; /* Ensures each item takes exactly 104px width */
  }
}
.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the images cover the grid area */
  border-radius: 13px;
}

/* */
.signUpPage-wrapper {
  max-width: 72.125rem;
  width: 96%;
  background-color: #fff;
  margin: 10rem auto 9rem auto;
  border-radius: 1.125rem;
  padding-block: 2.5rem 2rem;
  display: flex;
  justify-content: space-between;
}
.newsignup-title-container {
  /*padding-top: 40px;*/
  text-align: center;
  margin-bottom: 1.75rem;
}
.new-signUpPage-form {
  max-width: 30.8rem;
  margin: 0 auto 0 auto;
  font-size: 15px;
}
.new-signUpPage-form .form-group {
  margin-bottom: 1.5rem;
}

.new-signUpPage-form .form-group label {
  color: #4f4f4f;
  font-size: 15px;
}
.new-signUpPage-form .form-group input {
  display: block;
  width: 100%;
  padding: 11px 25px 11px 25px;
  border-radius: 11px;
  color: #4f4f4f;
  border: 1px solid #e4e4e4;
  background-color: #f3f3f3;
}

.new-signUpPage-form .form-group input:focus {
  outline: 1px solid #00a4b6;
}
.new-signUpPage-form .form-group input::placeholder {
  color: #636363;
  font-size: 1rem;
}

.new-signUpPage-form .form-group input::-webkit-input-placeholder {
  color: #636363;
  font-size: 1rem;
}

.new-signUpPage-form .form-group input::-moz-placeholder {
  color: #636363;
  font-size: 1rem;
}

.new-signUpPage-form .form-group input:-ms-input-placeholder {
  color: #636363;
  font-size: 1rem;
}

.new-signUpPage-form .form-group input::-ms-input-placeholder {
  color: #636363;
  font-size: 1rem;
}

.new-signup-button {
  border: 1px solid #626262;
  background: #626262;
  color: #ffffff;
  padding-block: 11px;
  margin-block: 21px;
  border-radius: 11px;
  transition: all 0.3s ease-in-out;
}

.new-signUpPage-form button:hover {
  background: #ffffff;
  color: #626262;
}

.new-signUpPage-form p {
  color: #6b6b6b;
}
.new-signUpPage-form a {
  color: #00a4b6;
}
.signUpPage-wrapper__right-column,
.signUpPage-wrapper__left-column {
  flex: 1;
}

.signUpPage-wrapper__right-column {
  text-align: center;
}

.newsignup-header,
.newsignup-description {
  color: #434343;
}
.newsignup-header {
  font-size: 1.563rem;
  line-height: 1.476;
}

.newsignup-description {
  font-size: 1.125rem;
  line-height: 1.5;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100px; /* Set to match select width */
}

.country-code-dropdown {
  -webkit-appearance: none; /* Remove default styles in Chrome/Safari */
  -moz-appearance: none; /* Remove default styles in Firefox */
  appearance: none; /* Standard appearance reset */
  display: inline-block;
  width: 100%; /* Full width of container */
  padding: 11px 0 11px 20.5px; /* Padding to make room for the arrow */
  border-radius: 11px 0 0 11px;
  border: 1px solid #e4e4e4;
  border-right: none;
  background-color: #f3f3f3;
  outline: none;
  font-size: 16px; /* Add font size for consistency */
  cursor: pointer;
}

.select-arrow {
  position: absolute;
  right: 0px; /* Position arrow within padding area */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the arrow is not clickable */
  transition: transform 0.3s ease;
}

.new-signUpPage-form .form-group .special-phone-input input {
  border-radius: 0 11px 11px 0;
  border-left: none;
  padding-left: 20px;
  outline: none;
}

/* error */
.new-signUpPage-form .form-group .invalid {
  outline: 1px solid var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.new-signUpPage-form .form-group .invalidSpecial {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* congratulation */
.newsignup-congratulation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 601px;
}
.newsignup-congratulation {
  /*margin: 0 auto;*/
  margin-top: 20px;
  width: 90%;
  max-width: 25rem;
}
.newsignup-congratulation img {
  margin-bottom: 2.375rem !important;
  margin-left: -0.625rem;
}
.newsignup-congratulation button {
  margin-top: 1.25rem;
}

@media (max-width: 75rem) {
  .newsignup-congratulation {
    margin-top: -6rem;
  }
  .hide-for-pc {
    display: none;
  }
  .signUpPage-wrapper {
    display: block;
    margin-top: 120px;
  }

  .signUpPage-wrapper {
    max-width: 650px;
    /*  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);*/

    padding-bottom: 40px;
  }
  .signUpPage-wrapper form {
    padding-inline: 20px;
  }
  .new-signUpPage-form {
    /* width: 100%;*/
    max-width: 550px;
    margin-bottom: 2rem;
  }
  .newsignup-congratulation {
    width: 100%;
    padding-inline: 10px;
  }
}

@media (max-width: 48rem) {
  .newsignup-congratulation-container {
    min-height: 680px;
  }
  .brands-grid-wrapper {
    width: 95%;
  }
  .signUpPage-wrapper {
    margin-top: 70px;
    padding-bottom: 20px;
  }
  .newsignup-header {
    font-size: 1.25rem;
  }
  .newsignup-description,
  .new-signUpPage-form .form-group label {
    font-size: 15.5px;
  }
  .signUpPage-wrapper__left-column {
    padding-block: 41px 18px;
    margin-bottom: 35px;
    border-radius: 18px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  }
}

@media (max-width: 26.813rem) {
  .newsignup-description {
    font-size: 14px;
  }
  .signUpPage-wrapper form {
    padding-inline: 3.5vw;
  }

  .brands-grid-Subwrapper {
    gap: 13px; /* Adds gap between items */
  }
  .grid-item {
    width: 104px;
    height: 104px;
    flex: 0 0 104px;
  }
}
</style>
